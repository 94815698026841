import React from 'react';
import Modal from 'react-modal';

const close = require("../../../assets/images/icons/close-button.png");

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    borderRadius          : '0px',
    padding               : '0px',
  }
};

class StyledModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <div>
        <div onClick={this.openModal} className="button-small button-primary">{this.props.title}</div>
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="flex flex-row justify-between items-center w-100 pl3 modal-header">
            <h4>{this.props.title}</h4>
            <div onClick={this.closeModal}>
              <div className="pa2">
                <img src={close} className="close-icon"/>
              </div>
            </div>
          </div>
          <div className="w-100 pa3">
            {this.props.children}
          </div>
        </Modal>
      </div>
    );
  }
}

export default StyledModal;
  