import React from 'react';
import StyledModal from "./helpers/StyledModal";
const axios = require('axios');

class ExperienceForm extends React.Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.state = {
      experience: props.experience,
    };
  }

  handleChange = (event) => {
    const experience = this.state.experience;
    experience[event.target.name] = event.target.value;
    this.setState({ experience: experience });
  }

  handleSubmit = () => {
    axios.post(`/api/experiences/`, {
      experience: this.state.experience
    })
    .then((response) => {
      this.modal.current.closeModal();
      this.props.submit(response);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  handleUpdate = (id) => {
    axios.put(`/api/experiences/${id}`, {
      experience: this.state.experience
    })
    .then((response) => {
      this.modal.current.closeModal();
      this.props.submit(response);
    })
    .catch((error) => {
      console.error(error);
    });
  }
  
	render() {
		return (
      <div>
        <StyledModal
          title={this.props.title}
          ref={this.modal}>
          <form>
            <p>Year</p>
            <input type="text" name="year" value={this.state.experience.year} onChange={this.handleChange} className="text-input"/>
            <p>Company</p>
            <input type="text" name="company" value={this.state.experience.company} onChange={this.handleChange} className="text-input"/>
            <p>Position</p>
            <input type="text" name="position" value={this.state.experience.position} onChange={this.handleChange} className="text-input"/>
            <div className="button-small button-primary" onClick={this.props.edit? () => this.handleUpdate(this.state.experience.id) : this.handleSubmit}>Save</div>
          </form>
        </StyledModal>
      </div>
		);
	}
}

export default ExperienceForm;
