import React from 'react';
import ExperienceForm from "./ExperienceForm";
const axios = require('axios');

class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiences: this.props.experiences
    }
  }

  handleDelete = (id) => {
    axios.delete(`/api/experiences/${id}`)
    .then((response) => {
      let newExp = []
      this.state.experiences.forEach((exp) => {
        if (exp.id != id) {
          newExp.push(exp);
        }
      })
      this.setState({ experiences: newExp });
    })
    .catch((error) => {
      console.error(error);
    });
  }

  onUpdate = (expRaw) => {
    const newExp = expRaw.data.experience;
    let updatedExp = []

    let updated = false;
    this.state.experiences.forEach((exp) => {
      if (exp.id == newExp.id) {
        updatedExp.push(newExp);
        updated = true;
      } else {
        updatedExp.push(exp);
      }
    })

    if (!updated) {
      updatedExp.push(newExp);
    }

    this.setState({ experiences: updatedExp });
  }
  
	render() {
		return (
      <div>
        <div className="flex flex-row justify-between items-center">
          <h2>Experience</h2>
          <ExperienceForm
            experience={{
              company: "",
              position: "",
              year: "",
              user_id: this.props.user_id
            }}
            title="New"
            edit={false}
            submit={this.onUpdate}
          />
        </div>
        {this.state.experiences.map((experience) => {
          return (
            <div className="flex flex-column mt2" key={experience.id}>
              <div className="flex flex-row justify-end">
                <ExperienceForm
                  experience={experience}
                  title="Edit"
                  edit={true}
                  submit={this.onUpdate}
                />
                <div className="button-small button-primary ml2" onClick={() => this.handleDelete(experience.id)}>Delete</div>
              </div>
              {experience.year && 
                <div className="flex flex-row justify-start">
                  <h4 className="mr2">Year</h4>
                  <p>{experience.year}</p>
                </div>
              }
              {experience.company && 
                <div className="flex flex-row justify-start">
                  <h4 className="mr2">Company</h4>
                  <p>{experience.company}</p>
                </div>
              }
              {experience.position && 
                <div className="flex flex-row justify-start">
                  <h4 className="mr2">Position</h4>
                  <p>{experience.position}</p>
                </div>
              }
            </div>
          )})
        }
      </div>
		);
	}
}

export default Experience;
